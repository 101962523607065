<template>
  <div class="page bg-white">
    <div v-if="!state.userCanLogin" class="page d-flex justify-content-center align-content-center">
      <van-loading v-if="!state.wechatAuthError" class="justify-content-center align-content-center" color="#A5D63F"
        size="36" vertical>
        加载中
      </van-loading>
      <van-empty v-else image="error" :description="state.wechatAuthError">
        <van-button type="primary" plain @click="redoWechatAuth">重新授权</van-button>
      </van-empty>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Field,
  Checkbox,
  Toast,
  Tab,
  Tabs,
  Image as VanImage,
} from "vant";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { getAuthUrl, wechatAuth } from "@/api/wechat.service";
import { hideWechatOptionMenu } from "@/utils/util";
import { createDeviceDetector } from "next-vue-device-detector";

export default {
  name: "Login",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },

  setup() {
    const device = createDeviceDetector();
    const { wechat } = device;

    const route = useRoute();
    const state = reactive({
      userCanLogin: false,
      wechatAuthError: null,
      userinfo: { phone: "" },
      realPhone: "", // 微信用户已经绑定过的手机, 此时的 phone 是脱敏显示的
      phone: "",
      password: "",
      smscode: "",
      invitation_code: "",
      has_invitation_code: false,
      argee: false,
      smsButtonText: "发送验证码",
      remainingTime: 0,
    });

    const redoWechatAuth = () => {
      const url = location.href.split("/wechat-login")[0];
      window.location.href = `${url}/wechat-login?reauth`;
    };

    const preauth = async (queryCode, queryState) => {
      try {
        // 购买页面使用后立即删除 sessionStorage.preauth
        const { result } = await wechatAuth(queryCode, queryState);
        window.sessionStorage.setItem("preauth", JSON.stringify(result));
        let redirect = window.sessionStorage.getItem("login_redirect_url");
        window.sessionStorage.removeItem("login_redirect_url");
        window.location.href = redirect ? redirect : "/";
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        state.wechatAuthError = msg ? msg : "获取微信授权失败";
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const jumptoWechat = () => {
      const callback_url = location.href.split("#")[0];
      const linker = callback_url.indexOf("?") == -1 ? "?" : "&";
      const redirect_url = getAuthUrl(
        `${callback_url}${linker}_t=${new Date().getTime()}`
      );
      window.location.href = redirect_url;
    };

    onMounted(() => {
      if (wechat) {
        const { code, state } = route.query;
        if (code && state) {
          preauth(code, state);
          hideWechatOptionMenu();
        } else {
          setTimeout(() => {
            jumptoWechat();
          }, 1200);
        }
      } else {
        // 引导至微信内访问
        Toast({
          message: "请在微信内访问此页面",
          position: "top",
          onClose: () => {
            jumptoWechat();
          },
        });
      }
    });

    return { state, redoWechatAuth };
  },
};
</script>
